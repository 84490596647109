import React, { MutableRefObject, useState } from 'react';
import { MutationResponse } from '../../../../models/DataTypes';
import { createFederationConfigGql } from '../../../../helpers/gqlQueries';
import { useMutation } from '@apollo/client';
import { useRefetch } from '../../../../RefetchContext';
import {
  validateFederationClientId,
  validateFederationConfigDescription,
  validateFederationDiscoveryUrl,
  validateFederationDisplayName,
  validateFederationName,
} from '@yonomi/util-validators';
import { trimObjectValues } from '../../../../helpers/dataUtils';
import { ConnectionType, getConnectionTypeValue } from '../../../../models/ConnectionType';
import { FormControl, FormHelperText, MenuItem, Stack } from '@mui/material';
import { StyledTextField } from '../../../common/Theme';
import InfoToolTip from '../../../common/InfoToolTip';
import SubmitButtonWithLoader from '../../../common/SubmitButtonWithLoader';
import Box from '@mui/material/Box';

const CREATE_FEDERATION_CONFIG = createFederationConfigGql();

interface FederationConfigFormData {
  name: string;
  displayName: string;
  description: string;
  connectionType: string;
  federationDiscoveryUrl: string;
  federationClientId: string;
}

interface CreateFederationConfigFormProps {
  devGroupId: string;
  onCompleted?: MutableRefObject<(response: MutationResponse) => void>;
  onError: (hasError: boolean, message: string) => void;
}

const CreateFederationConfigForm = (props: CreateFederationConfigFormProps) => {
  //GQL
  const [
    createFederationConfig,
    { error: createFederationConfigError, loading: creatingFederationConfig },
  ] = useMutation(CREATE_FEDERATION_CONFIG);
  const { refetchByKey } = useRefetch();
  const refetchFederationConfigs = () => refetchByKey('listFederationConfigs');

  //States
  const [formData, setFormData] = useState<FederationConfigFormData>({
    name: '',
    displayName: '',
    description: '',
    connectionType: 'OPEN_ID_CONNECT',
    federationDiscoveryUrl: '',
    federationClientId: '',
  });

  const [errors, setErrors] = useState<FederationConfigFormData>({
    name: '',
    displayName: '',
    description: '',
    connectionType: '',
    federationDiscoveryUrl: '',
    federationClientId: '',
  });

  //Handlers
  const validateForm = (data: FederationConfigFormData) => {
    let valid = true;
    const newErrors: Record<keyof FederationConfigFormData, string> = {
      name: '',
      displayName: '',
      description: '',
      connectionType: '',
      federationDiscoveryUrl: '',
      federationClientId: '',
    };

    try {
      validateFederationName(data.name);
    } catch (error) {
      valid = false;
      let errorMessage = 'Invalid Federation Config Name';
      if (error instanceof Error) errorMessage = error.message;
      newErrors.name = errorMessage;
    }

    if (data.displayName) {
      try {
        validateFederationDisplayName(data.displayName);
      } catch (error) {
        valid = false;
        let errorMessage = 'Invalid Federation Config Display Name';
        if (error instanceof Error) errorMessage = error.message;
        newErrors.displayName = errorMessage;
      }
    }

    try {
      validateFederationConfigDescription(data.description);
    } catch (error) {
      valid = false;
      let errorMessage = 'Invalid Federation Config Description';
      if (error instanceof Error) errorMessage = error.message;
      newErrors.description = errorMessage;
    }

    try {
      validateFederationDiscoveryUrl(data.federationDiscoveryUrl);
    } catch (error) {
      valid = false;
      let errorMessage = 'Invalid Federation Config Discovery URL';
      if (error instanceof Error) errorMessage = error.message;
      newErrors.federationDiscoveryUrl = errorMessage;
    }

    try {
      validateFederationClientId(data.federationClientId);
    } catch (error) {
      valid = false;
      let errorMessage = 'Invalid Federation Config Client ID';
      if (error instanceof Error) errorMessage = error.message;
      newErrors.federationClientId = errorMessage;
    }

    setErrors({ ...errors, ...newErrors });
    return valid;
  };

  const resetForm = () => {
    setFormData({
      name: '',
      displayName: '',
      description: '',
      connectionType: '',
      federationDiscoveryUrl: '',
      federationClientId: '',
    });
    setErrors({
      name: '',
      displayName: '',
      description: '',
      connectionType: '',
      federationDiscoveryUrl: '',
      federationClientId: '',
    });
  };

  const handleCompleted = (data: any) => {
    resetForm();
    refetchFederationConfigs();
    props.onCompleted?.current({
      data: data,
      message: `${data?.createFederationConfig?.name} Federation Config created successfully`,
    });
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setFormData({
      ...formData,
      connectionType: event.target.value as string,
    });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const trimmedData = trimObjectValues(formData);
    setFormData(trimmedData);
    if (validateForm(trimmedData)) {
      try {
        props.onError(false, '');
        await createFederationConfig({
          variables: {
            devGroupId: props.devGroupId,
            name: trimmedData.name,
            displayName: trimmedData.displayName,
            description: trimmedData.description,
            connectionType: getConnectionTypeValue(trimmedData.connectionType) ?? ConnectionType.OpenIdConnect,
            federationDiscoveryUrl: trimmedData.federationDiscoveryUrl,
            federationClientId: trimmedData.federationClientId,
          },
          onCompleted: (data) => handleCompleted(data),
        });
      } catch (error) {
        let errorMessage = `Failed to create Federation Config`;
        if (createFederationConfigError) {
          errorMessage = `${errorMessage}: ${createFederationConfigError.message}`;
        } else if (error instanceof Error) errorMessage = `${errorMessage}: ${error.message}`;
        props.onError(true, errorMessage);
      }
    }
  };

  const isFormIncomplete: boolean =
    formData.name.trim() === '' ||
    formData.description.trim() === '' ||
    formData.connectionType.trim() === '' ||
    formData.federationDiscoveryUrl.trim() === '' ||
    formData.federationClientId.trim() === '';

  return (
    <form id={'form-create-federation-config'} onSubmit={handleSubmit}>
      <FormControl>
        <Stack direction={'row'} alignItems={'center'}>
          <Box>
            <StyledTextField
              required
              autoFocus
              fullWidth
              autoComplete="off"
              id="input-create-federation-config-name"
              label="Federation Config Name"
              name="name"
              type="text"
              onChange={handleInputChange}
              value={formData.name}
              InputLabelProps={{
                shrink: true,
              }}
              error={!!errors.name}
            />
            {errors.name && (
              <FormHelperText id="name-helper" error={!!errors.name}>
                {errors.name}
              </FormHelperText>
            )}
          </Box>
          <InfoToolTip
            id={'tooltip-create-federation-config-name'}
            sx={{ marginTop: '24px' }}
            isOffColor={true}
            placement="right"
            title={'This serves as a logical identifier and cannot be modified after creation.'}
          />
        </Stack>

        <StyledTextField
          fullWidth
          autoComplete="off"
          id="input-create-federation-config-display-name"
          label="Display Name"
          name="displayName"
          type="text"
          onChange={handleInputChange}
          value={formData.displayName}
          InputLabelProps={{
            shrink: true,
          }}
          error={!!errors.displayName}
        />
        {errors.displayName && (
          <FormHelperText id="name-helper" error={!!errors.displayName}>
            {errors.displayName}
          </FormHelperText>
        )}

        <StyledTextField
          required
          autoComplete="off"
          id="input-create-federation-config-description"
          label="Description"
          name="description"
          type="text"
          onChange={handleInputChange}
          value={formData.description}
          InputLabelProps={{
            shrink: true,
          }}
          error={!!errors.description}
        />
        {errors.description && (
          <FormHelperText id="description-helper" error={!!errors.description}>
            {errors.description}
          </FormHelperText>
        )}

        <Stack direction={'row'} alignItems={'center'}>
          <Box>
            <StyledTextField
              required
              autoComplete="off"
              id="input-create-federation-config-discovery-url"
              label="Federation Discovery URL"
              name="federationDiscoveryUrl"
              type="text"
              onChange={handleInputChange}
              value={formData.federationDiscoveryUrl}
              InputLabelProps={{
                shrink: true,
              }}
              error={!!errors.federationDiscoveryUrl}
            />
            {errors.federationDiscoveryUrl && (
              <FormHelperText id="discovery-url-helper" error={!!errors.federationDiscoveryUrl}>
                {errors.federationDiscoveryUrl}
              </FormHelperText>
            )}
          </Box>
          <InfoToolTip
            id={'tooltip-create-federation-config-discovery-url'}
            sx={{ marginTop: '24px' }}
            isOffColor={true}
            placement="right"
            title={'Enter the URL of the discovery document of the Identity provider you want to connect with.'}
          />
        </Stack>

        <Stack direction={'row'} alignItems={'center'}>
          <Box>
            <StyledTextField
              required
              autoComplete="off"
              id="input-create-federation-config-client-id"
              label="Federation Client ID"
              name="federationClientId"
              type="text"
              onChange={handleInputChange}
              value={formData.federationClientId}
              InputLabelProps={{
                shrink: true,
              }}
              error={!!errors.federationClientId}
            />
            {errors.federationClientId && (
              <FormHelperText id="federation-client-id-helper" error={!!errors.federationClientId}>
                {errors.federationClientId}
              </FormHelperText>
            )}
          </Box>
          <InfoToolTip
            id={'tooltip-create-federation-config-client-id'}
            sx={{ marginTop: '24px' }}
            isOffColor={true}
            placement="right"
            title={
              "Client ID from the Identity provider you want to connect with. Obtaining the Client ID differs across providers. Please check your provider's documentation."
            }
          />
        </Stack>

        <StyledTextField
          required
          select
          id="input-create-federation-config-connection-type"
          label="Connection Type"
          name="connectionType"
          onChange={handleSelectChange}
          InputLabelProps={{
            shrink: true,
          }}
          error={!!errors.connectionType}
          value={formData.connectionType}
        >
          {Object.values(ConnectionType).map((value) => (
            <MenuItem key={value} value={value}>
              {value.replaceAll('_', ' ')}
            </MenuItem>
          ))}
        </StyledTextField>

        <SubmitButtonWithLoader
          id={'btn-create-federation-config-submit'}
          disabled={isFormIncomplete || creatingFederationConfig}
          label={'Create Federation Config'}
          loading={creatingFederationConfig}
        />
      </FormControl>
    </form>
  );
};

export default CreateFederationConfigForm;
