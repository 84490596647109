export const standardValidator = (field: string, fieldName = 'Name'): void => {
  const nameValidator = /^(?!\s+$)[a-zA-Z0-9]+(?:[ -][a-zA-Z0-9]+)*$/;

  if (!nameValidator.test(field)) {
    throw new Error(
      `Invalid ${fieldName}. Please enter a string with alphanumeric characters, (optional) non-consecutive spaces, (optional) non-consecutive dashes, without leading or trailing spaces or dashes.`
    );
  }
};

export const validateInstallationName = (installationName: string): void => {
  standardValidator(installationName);
};

export const validateOrganizationName = (organizationName: string): void => {
  standardValidator(organizationName);
};

export const validateDevGroupName = (devGroupName: string): void => {
  standardValidator(devGroupName);
};

export const validateAppClientName = (appClientName: string): void => {
  standardValidator(appClientName);
};

export const validateAppClientDescription = (appClientName: string): void => {
  standardValidator(appClientName, 'Description');
};

export const validateIntegrationName = (integrationName: string): void => {
  standardValidator(integrationName);
};

export const validateIntegrationDescription = (appClientName: string): void => {
  standardValidator(appClientName, 'Description');
};

type WebProtocol = 'http:' | 'https:';

export const validateUrl = (url: string, allowedProtocols: WebProtocol[] | null, errorMessage: string): void => {
  try {
    const parsed = new URL(url.toLowerCase());
    if (allowedProtocols && !allowedProtocols.includes(parsed.protocol as WebProtocol)) {
      throw new Error('invalid protocol');
    }
  } catch (err) {
    throw new Error(errorMessage);
  }
};

export const validateLoginUri = (loginUrl: string): void => {
  validateUrl(loginUrl, ['http:', 'https:'], 'Invalid Login URI. Please enter a valid URI.');
};

export const validateCallbackUrls = (redirectUrls: string[]): void => {
  // the Developer could specify a redirect URL with a custom protocol, e.g. my-app://callback
  redirectUrls.map((redirectUrl) => validateUrl(redirectUrl, null, 'Invalid Callback URL. Please enter a valid URL.'));
};

export const validateLogoutUrls = (logoutUrls: string[]): void => {
  // the Developer could specify a logout URL with a custom protocol, e.g. my-app://logout
  logoutUrls.map((logoutUrl) => validateUrl(logoutUrl, null, 'Invalid Logout URL. Please enter a valid URL.'));
};

export const validateAllowedWebOrigins = (allowedWebOrigins: string[]): void => {
  allowedWebOrigins?.map((webOrigin) =>
    validateUrl(webOrigin, ['http:', 'https:'], 'Invalid Allowed Web Origin. Please enter a valid URL.')
  );
};

export const validateFederationDiscoveryUrl = (federationDiscoveryUrl: string): void => {
  validateUrl(
    federationDiscoveryUrl,
    ['http:', 'https:'],
    'Invalid Federation Discovery URL. Please enter a valid URL.'
  );
};

export const validateFederationName = (federationName: string): void => {
  //Pattern used by auth0 for validating connection names
  const nameValidator = /^[a-zA-Z0-9](-[a-zA-Z0-9]|[a-zA-Z0-9])*$/;
  if (!nameValidator.test(federationName)) {
    throw new Error(
      'Invalid Name. Please enter a string with alphanumeric characters, (optional) non-consecutive dashes, without leading or trailing dashes. Spaces are NOT allowed'
    );
  }
};

export const validateFederationDisplayName = (displayName: string): void => {
  standardValidator(displayName, 'Display Name');
};

export const validateFederationConfigDescription = (fedConfigDescription: string): void => {
  standardValidator(fedConfigDescription, 'Description');
};

export const validateFederationClientId = (federationClientId: string): void => {
  const idValidator = /^[a-zA-Z0-9-:./_~]+$/;
  if (!idValidator.test(federationClientId)) {
    throw new Error(`Invalid Federation Client ID. Must match the required pattern: /^[a-zA-Z0-9-:./_~]+$/`);
  }
};

export const validateWebhookConfigName = (webhookConfigName: string): void => {
  standardValidator(webhookConfigName);
};

export const validateWebhookConfigDescription = (webhookConfigDescription: string): void => {
  standardValidator(webhookConfigDescription, 'Description');
};

export const validateWebhookConfigEndpoint = (webhookConfigEndpoint: string): void => {
  validateUrl(webhookConfigEndpoint, ['https:'], 'Invalid Endpoint. Please enter a valid endpoint.');
};
