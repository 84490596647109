import { gql } from '@yonomi/interfaces-graph-api-developer';

export const listDevGroupsGql = () => {
  return gql(`
  query ListDevGroups {
    listDevGroups {
        edges {
            node {
                devGroupId
                name
                createdAt
                updatedAt
                organizationId
                federationManagementConfig {
                    isActive
                    allowedFederationConfigs
                }
            }
        }
    }
  }
`);
};

export const createDevGroupGql = () => {
  return gql(`
  mutation CreateDevGroup($name: String!) {
    createDevGroup(devGroupAttrs: { name: $name }) {
      devGroupId
      name
      createdAt
      updatedAt
      organizationId
    }
  }
`);
};

export const getDevGroupGql = () => {
  return gql(`query GetDevGroup($devGroupId: ID!) {
    getDevGroup(devGroupId: $devGroupId) {
        devGroupId        
        name
        organizationId
        federationManagementConfig {
            isActive
            allowedFederationConfigs
        }
    }
}`);
};

export const listClientCredsGql = () => {
  return gql(`query ListClientCreds($devGroupId: ID!, $first: Int, $after: String) {
    listAppClients(
        devGroupId: $devGroupId
        appClientFilter: USER_AUTHZ
        paginationParams: { first: $first, after: $after }
    ) {
        edges {
            node {
                ... on ClientCreds {
                    name
                    description
                    appType
                    clientId
                    clientSecret
                    loginUri
                    callbackUrls
                    logoutUrls
                    allowedWebOrigins
                    createdAt
                    updatedAt
                    devGroupId
                }
            }
        }
        pageInfo {
            hasNextPage
            endCursor
        }
    }
}`);
};

export const listM2mCredsGql = () => {
  return gql(`query ListM2mCreds($devGroupId: ID!, $first: Int, $after: String) {
    listAppClients(
        devGroupId: $devGroupId
        appClientFilter: M2M_AUTHZ
        paginationParams: { first: $first, after: $after }
    ) {
        edges {
            node {
                ... on M2MCreds {
                    name
                    description
                    clientId
                    clientSecret
                    createdAt
                    updatedAt
                    devGroupId
                    m2mCredsIntegrationAssoc {
                        edges {
                            node {
                                integration {
                                    name
                                    integrationId
                                }
                            }
                        }
                    }
                }
            }
        }
        pageInfo {
            hasNextPage
            endCursor
        }
    }
}`);
};

export const updateDevGroupGql = () => {
  return gql(`mutation UpdateDevGroup($devGroupId: ID!, $name: String!) {
    updateDevGroup(devGroupId: $devGroupId, devGroupAttrs: { name: $name }) {
        devGroupId
        name
        createdAt
        updatedAt
        organizationId
    }
}`);
};

export const deleteDevGroupGql = () => {
  return gql(`mutation DeleteDevGroup($devGroupId: ID!) {
    deleteDevGroup(devGroupId: $devGroupId) {
        requestStatus
    }
}`);
};

export const createClientCredsGql = () => {
  return gql(
    `mutation CreateClientCreds(
                $devGroupId: ID!, 
                $name: String!, 
                $description: String, 
                $appType: AppTypes!, 
                $loginUri: String, 
                $callbackUrls: [String!]!, 
                $logoutUrls: [String!]!,
                $allowedWebOrigins: [String!]) {
     createAppClient(
        devGroupId: $devGroupId
        appClientAttrs: {
            clientCredsAttrs: {
                name: $name
                description: $description
                appType: $appType
                loginUri: $loginUri
                callbackUrls: $callbackUrls
                logoutUrls: $logoutUrls
                allowedWebOrigins: $allowedWebOrigins
            }
        }
    ) {
        ... on ClientCreds {
            name
            description
            appType
            clientId
            clientSecret
            loginUri
            callbackUrls
            logoutUrls
            allowedWebOrigins
            createdAt
            updatedAt
            devGroupId
        }
    }
}`
  );
};

export const updateClientCredsGql = () => {
  return gql(
    `mutation UpdateClientCreds(
                $clientId: ID!, 
                $name: String!, 
                $description: String, 
                $appType: AppTypes!, 
                $loginUri: String, 
                $callbackUrls: [String!]!, 
                $logoutUrls: [String!]!, 
                $allowedWebOrigins: [String!]) {
     updateAppClient(
        clientId: $clientId
        appClientAttrs: {
            clientCredsAttrs: {
                name: $name
                description: $description
                appType: $appType
                loginUri: $loginUri
                callbackUrls: $callbackUrls
                logoutUrls: $logoutUrls
                allowedWebOrigins: $allowedWebOrigins
            }
        }
    ) {
        ... on ClientCreds {
            name
            description
            appType
            clientId
            clientSecret
            loginUri
            callbackUrls
            logoutUrls
            allowedWebOrigins
            createdAt
            updatedAt
            devGroupId
        }
    }
  }`
  );
};

export const createM2MCredsGql = () => {
  return gql(
    `mutation CreateM2MCreds(
                $devGroupId: ID!, 
                $name: String!, 
                $description: String) {
     createAppClient(
        devGroupId: $devGroupId
        appClientAttrs: {
            m2mCredsAttrs: {
                name: $name
                description: $description
            }
        }
    ) {
        ... on M2MCreds {
            name
            description
            clientId
            clientSecret
            createdAt
            updatedAt
            devGroupId
        }
    }
}`
  );
};

export const updateM2MCredsGql = () => {
  return gql(`mutation UpdateM2MCreds(
                $clientId: ID!, 
                $name: String!, 
                $description: String) {
     updateAppClient(
        clientId: $clientId
        appClientAttrs: {
            m2mCredsAttrs: {
                name: $name
                description: $description
            }
        }
    ) {
        ... on M2MCreds {
            name
            description
            clientId
            clientSecret
            createdAt
            updatedAt
            devGroupId
        }
    }
  }`);
};

export const deleteAppClientGql = () => {
  return gql(`mutation DeleteAppClient($clientId: ID!) {
    deleteAppClient(clientId: $clientId) {
        requestStatus
    }
}`);
};

export const listFederationConfigsGql = () => {
  return gql(`query ListFederationConfigs($devGroupId: ID!, $first: Int, $after: String) {
    listFederationConfigs(devGroupId: $devGroupId, paginationParams: { first: $first, after: $after }) {
        edges {
            node {
                name
                displayName
                description
                connectionType
                federationDiscoveryUrl
                federationClientId
                callbackUrl
                connectionId
                fedConfigId
                createdAt
                updatedAt               
            }
        }
        pageInfo {
            hasNextPage
            endCursor
        }
    }
}`);
};

export const createFederationConfigGql = () => {
  return gql(`mutation CreateFederationConfig(
                $devGroupId: String!, 
                $name: String!,
                $displayName: String,
                $description: String!, 
                $connectionType: ConnectionType!, 
                $federationDiscoveryUrl: String!, 
                $federationClientId: ID!) {
    createFederationConfig(
        federationConfigAttrs: {        
            devGroupId: $devGroupId
            name: $name
            displayName: $displayName
            description: $description
            connectionType: $connectionType
            federationDiscoveryUrl: $federationDiscoveryUrl
            federationClientId: $federationClientId
        }
    ) {
        name,
        displayName,
        description
        connectionType
        federationDiscoveryUrl
        federationClientId
        callbackUrl
        connectionId
        fedConfigId
        createdAt
        updatedAt
    }
}`);
};

export const updateFederationConfigGql = () => {
  return gql(`mutation UpdateFederationConfig(
                $federationConfigId: ID!, 
                $displayName: String,
                $description: String!, 
                $federationDiscoveryUrl: String!, 
                $federationClientId: ID!) {
    updateFederationConfig(
        federationConfigId: $federationConfigId
        updateFederationConfigAttrs: {
            displayName: $displayName
            description: $description
            federationDiscoveryUrl: $federationDiscoveryUrl
            federationClientId: $federationClientId
        }
    ) {
        name
        displayName
        description
        connectionType
        federationDiscoveryUrl
        federationClientId
        callbackUrl
        connectionId
        fedConfigId
        createdAt
        updatedAt
    }
}`);
};

export const deleteFederationConfigGql = () => {
  return gql(`mutation DeleteFederationConfig($federationConfigId: ID!) {
    deleteFederationConfig(federationConfigId: $federationConfigId) {
        requestStatus
    }
}`);
};

export const listIntegrationsGql = () => {
  return gql(`query ListIntegrations($devGroupId: ID!, $first: Int, $after: String) {
     listIntegrations(devGroupId: $devGroupId, paginationParams: { first: $first, after: $after }) {
        edges {
            node {
                integrationId
                name
                description
                isPublic
                createdAt
                updatedAt
                devGroupId
                m2mCredsIntegrationAssoc {
                    edges {
                        node {
                            m2mCreds {
                                name
                                clientId
                            }
                        }
                    }
                }
                webhookConfigIntegrationAssoc {
                    edges {
                        node {
                            webhookConfig {
                                name
                                webhookConfigId
                            }
                        }
                    }
                }
            }
        }
        pageInfo {
            hasNextPage
            endCursor
        }
    }
}`);
};

export const createIntegrationGql = () => {
  return gql(`mutation CreateIntegration(
                $devGroupId: ID!, 
                $name: String!, 
                $description: String!, 
                $isPublic: Boolean!) {
    createIntegration(
        devGroupId: $devGroupId
        integrationAttrs: { name: $name, description: $description, isPublic: $isPublic }
    ) {
        integrationId
        name
        description
        isPublic
        createdAt
        updatedAt
        devGroupId
    }
}`);
};

export const updateIntegrationGql = () => {
  return gql(`mutation UpdateIntegration(
                $integrationId: ID!, 
                $name: String!, 
                $description: String!, 
                $isPublic: Boolean!) {
    updateIntegration(
        integrationId: $integrationId
        integrationAttrs: { name: $name, description: $description, isPublic: $isPublic }
    ) {
        integrationId
        name
        description
        isPublic
        createdAt
        updatedAt
        devGroupId
    }
}`);
};

export const deleteIntegrationGql = () => {
  return gql(`mutation DeleteIntegration($integrationId: ID!) {
    deleteIntegration(integrationId: $integrationId) {
        requestStatus
    }
}`);
};

export const addM2MCredsToIntegrationGql = () => {
  return gql(`mutation AddM2MCredsToIntegration(
                $integrationId: ID!, 
                $clientId: ID!) {
    addM2MCredsToIntegration(
        integrationId: $integrationId
        clientId: $clientId
    ) {
        integrationId
        clientId
        devGroupId
        createdAt
        updatedAt
    }
}`);
};

export const removeM2MCredsFromIntegrationGql = () => {
  return gql(`mutation RemoveM2MCredsFromIntegration(
                $integrationId: ID!, 
                $clientId: ID!) {
    removeM2MCredsFromIntegration(
        integrationId: $integrationId
        clientId: $clientId
    ) {
        requestStatus
    }
}`);
};

export const listWebhookConfigsGql = () => {
  return gql(`query ListWebhookConfigs($devGroupId: ID!, $first: Int, $after: String) {
    listWebhookConfigs(devGroupId: $devGroupId, paginationParams: { first: $first, after: $after }) {
        edges {
            node {
                webhookConfigId
                name
                description
                endpoint
                eventTypes
                createdAt
                updatedAt
                webhookConfigIntegrationAssoc {
                    edges {
                        node {
                            integration {
                                name
                                integrationId
                            }
                        }
                    }
                }
            }
        }
        pageInfo {
            hasNextPage
            endCursor
        }
    }
}`);
};

export const createWebhookConfigGql = () => {
  return gql(`mutation CreateWebhookConfig(
                $devGroupId: ID!, 
                $name: String!, 
                $description: String!, 
                $endpoint: String!, 
                $eventTypes: [WebhookEventType!]!) {
    createWebhookConfig(
        devGroupId: $devGroupId
        webhookConfigAttrs: {
            name: $name
            description: $description
            endpoint: $endpoint
            eventTypes: $eventTypes
        }
    ) {
        webhookConfigId
        name
        description
        endpoint
        eventTypes
        createdAt
        updatedAt
    }
}`);
};

export const updateWebhookConfigGql = () => {
  return gql(`mutation UpdateWebhookConfig(
                $webhookConfigId: ID!, 
                $name: String!, 
                $description: String!, 
                $endpoint: String!, 
                $eventTypes: [WebhookEventType!]!) {
    updateWebhookConfig(
        webhookConfigId: $webhookConfigId
        webhookConfigAttrs: {
            name: $name
            description: $description
            endpoint: $endpoint
            eventTypes: $eventTypes
        }
    ) {
        webhookConfigId
        name
        description
        endpoint
        eventTypes
        createdAt
        updatedAt
    }
}`);
};

export const deleteWebhookConfigGql = () => {
  return gql(`mutation DeleteWebhookConfig($webhookConfigId: ID!) {
    deleteWebhookConfig(webhookConfigId: $webhookConfigId) {
        requestStatus
    }
}`);
};

export const addWebhookConfigToIntegrationGql = () => {
  return gql(`mutation AddWebhookConfigToIntegration(
                $integrationId: ID!, 
                $webhookConfigId: ID!) {
    addWebhookConfigToIntegration(
        integrationId: $integrationId
        webhookConfigId: $webhookConfigId
    ) {
        integrationId
        webhookConfigId
        devGroupId
        createdAt
        updatedAt
    }
}`);
};

export const removeWebhookConfigFromIntegrationGql = () => {
  return gql(`mutation RemoveWebhookConfigFromIntegration(
                $integrationId: ID!, 
                $webhookConfigId: ID!) {
    removeWebhookConfigFromIntegration(
        integrationId: $integrationId
        webhookConfigId: $webhookConfigId
    ) {
        requestStatus
    }
}`);
};

export const getDevResourceCountGql = () => {
  return gql(`query GetResourcesCount($devGroupId: ID!) {
    clientCreds: getAppClientCount(devGroupId: $devGroupId, appClientFilter: USER_AUTHZ)
    m2mCreds: getAppClientCount(devGroupId: $devGroupId, appClientFilter: M2M_AUTHZ)
    getIntegrationCount(devGroupId: $devGroupId)
    getWebhookConfigCount(devGroupId: $devGroupId)
    getFederationConfigCount(devGroupId: $devGroupId)
    }`);
};
